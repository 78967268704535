import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Link, LinkContainer, StyledLink } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Link" title="Link" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-link"
    }}>{`Basic link`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Typography size=\"large\">\n    Hello world.\n    <Link href=\"http://phork.org\" target=\"_blank\">\n      I am a Link.\n    </Link>{\' \'}\n    Goodbye.\n  </Typography>\n  <br />\n  <LinkContainer as=\"p\">\n    Hello world.\n    <a href=\"http://phork.org\" rel=\"noreferrer\" target=\"_blank\">\n      I am within a LinkContainer.\n    </a>{\' \'}\n    I also have a paragraph wrapper. Goodbye.\n  </LinkContainer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Typography,
      PageTitle,
      ThemeWrapper,
      Link,
      LinkContainer,
      StyledLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Typography size="large" mdxType="Typography">
      Hello world.
      <Link href="http://phork.org" target="_blank" mdxType="Link">
        I am a Link.
      </Link> Goodbye.
    </Typography>
    <br />
    <LinkContainer as="p" mdxType="LinkContainer">
      Hello world.
      <a href="http://phork.org" rel="noreferrer" target="_blank">
        I am within a LinkContainer.
      </a> I also have a paragraph wrapper. Goodbye.
    </LinkContainer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "underlined-link"
    }}>{`Underlined link`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Typography size=\"large\">\n    Hello world.\n    <Link underline href=\"http://phork.org\" target=\"_blank\">\n      I am a Link.\n    </Link>{\' \'}\n    Goodbye.\n  </Typography>\n  <br />\n  <LinkContainer underline as=\"p\">\n    <Typography size=\"xlarge\">\n      Hello world.\n      <a href=\"http://phork.org\" rel=\"noreferrer\" target=\"_blank\">\n        I am within a LinkContainer and a Typography component.\n      </a>{\' \'}\n      Goodbye.\n    </Typography>\n  </LinkContainer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Typography,
      PageTitle,
      ThemeWrapper,
      Link,
      LinkContainer,
      StyledLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Typography size="large" mdxType="Typography">
      Hello world.
      <Link underline href="http://phork.org" target="_blank" mdxType="Link">
        I am a Link.
      </Link> Goodbye.
    </Typography>
    <br />
    <LinkContainer underline as="p" mdxType="LinkContainer">
      <Typography size="xlarge" mdxType="Typography">
        Hello world.
        <a href="http://phork.org" rel="noreferrer" target="_blank">
          I am within a LinkContainer and a Typography component.
        </a> Goodbye.
      </Typography>
    </LinkContainer>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "contrast-link"
    }}>{`Contrast link`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper contrast>\n  <Typography size=\"large\">\n    Hello world.\n    <Link contrast href=\"http://phork.org\" target=\"_blank\">\n      I am a Link.\n    </Link>{\' \'}\n    Goodbye.\n  </Typography>\n  <br />\n  <LinkContainer contrast>\n    <Typography size=\"large\">\n      Hello world.\n      <a href=\"http://phork.org\" rel=\"noreferrer\" target=\"_blank\">\n        I am within a LinkContainer.\n      </a>{\' \'}\n      Goodbye.\n    </Typography>\n  </LinkContainer>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Typography,
      PageTitle,
      ThemeWrapper,
      Link,
      LinkContainer,
      StyledLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Typography size="large" mdxType="Typography">
      Hello world.
      <Link contrast href="http://phork.org" target="_blank" mdxType="Link">
        I am a Link.
      </Link> Goodbye.
    </Typography>
    <br />
    <LinkContainer contrast mdxType="LinkContainer">
      <Typography size="large" mdxType="Typography">
        Hello world.
        <a href="http://phork.org" rel="noreferrer" target="_blank">
          I am within a LinkContainer.
        </a> Goodbye.
      </Typography>
    </LinkContainer>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-link"
    }}>{`Styled link`}</h3>
    <Playground __position={4} __code={'<Typography size=\"large\">\n  Hello world.\n  <StyledLink\n    activeColor=\"#f18100\"\n    color=\"#ff8e0d\"\n    hoveredColor=\"#ff9b28\"\n    href=\"http://phork.org\"\n    target=\"_blank\"\n  >\n    I am a StyledLink.\n  </StyledLink>\n</Typography>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Typography,
      PageTitle,
      ThemeWrapper,
      Link,
      LinkContainer,
      StyledLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Typography size="large" mdxType="Typography">
    Hello world.
    <StyledLink activeColor="#f18100" color="#ff8e0d" hoveredColor="#ff9b28" href="http://phork.org" target="_blank" mdxType="StyledLink">
      I am a StyledLink.
    </StyledLink>
  </Typography>
    </Playground>
    <h3 {...{
      "id": "unstyled-block-link"
    }}>{`Unstyled block link`}</h3>
    <Playground __position={5} __code={'<Typography color=\"warning\" size=\"large\">\n  <Link block unstyled unthemed href=\"http://phork.org\" target=\"_blank\">\n    I am an unstyled link. I use currentColor.\n  </Link>\n</Typography>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Typography,
      PageTitle,
      ThemeWrapper,
      Link,
      LinkContainer,
      StyledLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Typography color="warning" size="large" mdxType="Typography">
    <Link block unstyled unthemed href="http://phork.org" target="_blank" mdxType="Link">
      I am an unstyled link. I use currentColor.
    </Link>
  </Typography>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "link"
    }}>{`Link`}</h3>
    <Props of={Link} mdxType="Props" />
    <h3 {...{
      "id": "styledlink"
    }}>{`StyledLink`}</h3>
    <Props of={StyledLink} mdxType="Props" />
    <h3 {...{
      "id": "linkcontainer"
    }}>{`LinkContainer`}</h3>
    <Props of={LinkContainer} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      